import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Slider, Switch } from 'antd'
import Button from '@/components/Button'
import { updateConfiguration } from '@/store/conversations/actions'
import { Container } from './styles'

const Configurations = () => {
  const dispatch = useDispatch()

  const { configuration } = useSelector((state) => state.conversations)
  const {
    prompt: defaultPrompt,
    simThreshold: defaultSimThreshold,
    temperature: defaultTemperature,
    isSystemPromptVisible: defaultIsSystemPromptVisible,
  } = configuration

  const [prompt, setPrompt] = useState(defaultPrompt)
  const [simThreshold, setSimThreshold] = useState(defaultSimThreshold)
  const [temperature, setTemperature] = useState(defaultTemperature)
  const [isSystemPromptVisible, setIsSystemPromptVisible] = useState(defaultIsSystemPromptVisible)

  const updateConfigurations = () => {
    dispatch(
      updateConfiguration({
        prompt,
        simThreshold,
        temperature,
        isSystemPromptVisible,
      }),
    )
  }

  return (
    <Container className="configurations-container">
      <div className="setting-box">
        <p className="name">Prompt</p>

        <small>valid replacements:</small>

        <ul>
          <li>
            <code>{'{embedding_text}'}</code> - the text of the videos
          </li>
        </ul>

        <div className="value-container">
          <Input.TextArea
            value={prompt}
            onChange={(evt) => setPrompt(evt.target.value)}
            rows={6}
            placeholder="Enter custom prompt"
          />
        </div>
      </div>

      <div className="setting-box">
        <p className="name">Similarity threshold</p>

        <div className="value-container">
          <Slider min={0} max={1} step={0.05} value={simThreshold} onChange={(newValue) => setSimThreshold(newValue)} />
          {simThreshold}
        </div>
      </div>

      <div className="setting-box">
        <p className="name">Temperature</p>

        <div className="value-container">
          <Slider min={0} max={2} step={0.1} value={temperature} onChange={(newValue) => setTemperature(newValue)} />
          {temperature}
        </div>
      </div>

      <div className="setting-box">
        <p className="name">Show system prompt</p>

        <div className="value-container">
          <Switch
            checked={isSystemPromptVisible}
            onChange={(checked) => setIsSystemPromptVisible(checked)}
            checkedChildren="yes"
            unCheckedChildren="no"
          />
        </div>
      </div>

      <div className="actions">
        <Button type="primary" onClick={updateConfigurations}>
          Update
        </Button>
      </div>
    </Container>
  )
}
export default Configurations
