import { APP_ERROR_UPDATED, APP_NOTIFICATION_UPDATED } from '@/store/app'

const setAppError = (error) => (dispatch) => {
  dispatch(APP_ERROR_UPDATED(error))
}

const setAppNotification = (notification) => (dispatch) => {
  dispatch(APP_NOTIFICATION_UPDATED(notification))
}

export { setAppError, setAppNotification }
