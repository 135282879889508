import { useEffect, useState } from 'react'
import { ArrowUpRightRegular } from '@fluentui/react-icons'
import { Collapse } from 'antd'
import Button from '@/components/Button'
import { Container } from './styles'

const RelatedContentItem = ({ item }) => {
  const [videoUrl, setVideoUrl] = useState(item?.passages?.[0]?.url)

  const getSimilarityIcon = (similarity) => {
    if (similarity >= 0.9) {
      return '🔥🔥🔥'
    }

    if (similarity >= 0.7) {
      return '🔥🔥'
    }

    if (similarity >= 0.5) {
      return '🔥'
    }

    return '🥶'
  }

  const getReadableTime = (timeInSecs) => {
    if (!timeInSecs) return

    let min = Math.floor((timeInSecs % (60 * 60)) / 60)
    min = min < 10 ? `0${min}` : min

    let sec = Math.floor(timeInSecs % 60)
    sec = sec < 10 ? `0${sec}` : sec

    return `${min}:${sec}`
  }

  const passages = item?.passages?.map((p, i) => ({
    key: i,
    label: (
      <div className="passage-header">
        <p className="time">At {getReadableTime(p.start)} mins</p>

        <p className="similarity">
          Similarity: {Math.round(p.similarity * 100)}% {getSimilarityIcon(p.similarity)}
        </p>
      </div>
    ),
    children: (
      <div className="passage" style={{ border: '1px solid #eee', padding: 12, marginBottom: 12 }}>
        <p className="text">{p.text}</p>

        {item?.passages?.length > 1 && (
          <div className="passage-actions">
            <Button
              type="primary"
              size="small"
              icon={<ArrowUpRightRegular />}
              onClick={() => setVideoUrl(p.url)}
              disabled={p.url === videoUrl}
            >
              {p.url === videoUrl ? 'Now playing' : 'Play this moment'}
            </Button>
          </div>
        )}
      </div>
    ),
  }))

  return (
    <Container className="related-content-container">
      <div className="related-content">
        <div className="info">
          <div className="header">
            <h4 className="title">{item?.title}</h4>
            <a className="pre-title" href={item?.channel?.url} target="_blank">
              {item?.channel?.name}
            </a>
          </div>

          <iframe src={videoUrl} title={item?.title} className="video" />
        </div>

        <div className="passages-container">
          <Collapse items={passages} defaultActiveKey={0} />
        </div>
      </div>
    </Container>
  )
}
export default RelatedContentItem
