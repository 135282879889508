import { createSlice } from '@reduxjs/toolkit'

const getDefaultConfiguration = () => {
  const storedConfiguration = localStorage.getItem('dw-trooper-configuration')

  const defaultConfiguration = storedConfiguration
    ? JSON.parse(storedConfiguration)
    : {
        prompt: '',
        simThreshold: 0.5,
        temperature: 1,
        isSystemPromptVisible: false,
      }

  return defaultConfiguration
}

const initialState = {
  isConversationLoading: false,
  currentConversation: [],
  relatedContent: null,
  configuration: getDefaultConfiguration(),
  error: null,
}

const getInitialState = () => {
  return {
    ...initialState,
    configuration: getDefaultConfiguration(),
  }
}

const slice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    SET_CONVERSATION_LOADING: (state, { payload }) => {
      state.isConversationLoading = payload
    },
    CONVERSATION_UPDATED: (state, { payload }) => {
      state.currentConversation = payload
      state.isConversationLoading = false
      state.error = null
    },
    RELATED_CONTENT_UPDATED: (state, { payload }) => {
      state.relatedContent = payload
    },
    CONFIGURATION_UPDATED: (state, { payload }) => {
      state.configuration = payload
    },
    RESET_CONVERSATIONS_STATE: () => getInitialState(),
    SET_CONVERSATION_ERROR: (state, { payload }) => {
      state.error = payload
      state.isConversationLoading = false
    },
  },
})

export const {
  SET_CONVERSATION_LOADING,
  CONVERSATION_UPDATED,
  RELATED_CONTENT_UPDATED,
  CONFIGURATION_UPDATED,
  RESET_CONVERSATIONS_STATE,
  SET_CONVERSATION_ERROR,
} = slice.actions
export default slice.reducer
