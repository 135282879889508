import OverlappedLoader from '@/components/OverlappedLoader'
import Navbar from './components/Navbar'
import { Layout, Content, ChildrenContent } from './styles'

const Layout1 = ({ navbar, isLoading, children }) => {
  return (
    <Layout hasSider={false}>
      {/* {navbar && <Navbar isLoading={isLoading} />} */}

      {isLoading && <OverlappedLoader />}

      <Content>{children && <ChildrenContent className="children-content">{children}</ChildrenContent>}</Content>
    </Layout>
  )
}

export default Layout1
