import axios from 'axios'
import { ragApiURL } from '@/helpers/env'

const API = axios.create({
  baseURL: ragApiURL,
  headers: {
    common: {
      Authorization: `Token ${import.meta.env.REACT_APP_RAG_API_TOKEN}`,
      'Content-Type': 'application/json',
    },
  },
  emulateJSON: true,
})

export default API
