import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import App from '@/App'
import store from '@/store'
import * as serviceWorker from '@/serviceWorker'
import reportWebVitals from '@/reportWebVitals'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <ReduxProvider store={store}>
      <App />
  </ReduxProvider>,
)

serviceWorker.unregister()

reportWebVitals()
