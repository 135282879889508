import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Container } from './styles'

const Answer = ({ text }) => {
  const [words, setWords] = useState([])

  useEffect(() => {
    setWords(text.split(' '))
  }, [text])

  return <Container>{words && <ReactMarkdown children={text} />}</Container>
}
export default Answer
