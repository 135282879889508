import {
  SET_CONVERSATION_LOADING,
  CONVERSATION_UPDATED,
  RELATED_CONTENT_UPDATED,
  CONFIGURATION_UPDATED,
  RESET_CONVERSATIONS_STATE,
  SET_CONVERSATION_ERROR,
} from '@/store/conversations'
import { postSearch, postGPTSearch } from '@/services/search'
import { showToast } from '@/utils/toast/index'

const getContext = (prompt, relatedContent) => {
  let auxPrompt = prompt

  const embeddingText = relatedContent?.map((item, i) => `Video ${i + 1}: ${item.full_text}`).join('\n\n')
  auxPrompt = auxPrompt.replace('{embedding_text}', embeddingText)

  return auxPrompt
}

const createRagSearch = (message, cb) => async (dispatch, getState) => {
  try {
    dispatch(SET_CONVERSATION_LOADING(true))

    const { conversations } = getState()
    const { configuration } = conversations
    const { prompt, simThreshold } = configuration

    const response = await postSearch({ query: message, sim_threshold: simThreshold })
    dispatch(RELATED_CONTENT_UPDATED(response?.results))

    const context = getContext(prompt || response?.context, response?.results, message)
    dispatch(addSystemMessageToConversation(context, true))

    if (cb) {
      cb(response?.results)
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_CONVERSATION_ERROR(message))
    showToast(message, 'error')
  } finally {
    dispatch(SET_CONVERSATION_LOADING(false))
  }
}

const createGPTSearch = (message, shouldPerformSearch) => async (dispatch, getState) => {
  try {
    dispatch(SET_CONVERSATION_LOADING(true))

    const { conversations } = getState()
    const { configuration, currentConversation } = conversations
    const { temperature } = configuration

    const newMessage = { role: 'user', content: message }
    const auxConversation = [...currentConversation, newMessage]

    dispatch(CONVERSATION_UPDATED(auxConversation))

    if (shouldPerformSearch) {
      const stream = await postGPTSearch(auxConversation, temperature)
      return stream
    }

    return null
  } catch (error) {
    const { message } = error
    dispatch(SET_CONVERSATION_ERROR(message))
    showToast(message, 'error')
  } finally {
    dispatch(SET_CONVERSATION_LOADING(false))
  }
}

const addSystemMessageToConversation = (message, isHidden) => async (dispatch, getState) => {
  const { conversations } = getState()
  const { currentConversation } = conversations

  const newMessage = { role: 'system', content: message, isHidden }
  const auxConversation = [...currentConversation, newMessage]

  dispatch(CONVERSATION_UPDATED(auxConversation))
}

const updateConfiguration = (config) => async (dispatch) => {
  localStorage.setItem('dw-trooper-configuration', JSON.stringify(config))
  dispatch(CONFIGURATION_UPDATED(config))
  showToast('Configuration updated')
}

const resetConversationsState = () => (dispatch) => {
  dispatch(RESET_CONVERSATIONS_STATE())
}

export {
  createRagSearch,
  createGPTSearch,
  addSystemMessageToConversation,
  updateConfiguration,
  resetConversationsState,
}
