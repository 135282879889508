import styled from 'styled-components'

export const Container = styled.div`
  .word {
    animation: fadeIn 0.5s ease-in-out forwards;
    opacity: 0;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
