import { createParser } from 'eventsource-parser'
import ragAPI from '@/services/ragAPI'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const postSearch = async (data) => {
  const route = `search`
  return ragAPI
    .post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postGPTSearch = async (messages, temperature) => {
  try {
    const encoder = new TextEncoder()
    const decoder = new TextDecoder()

    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${import.meta.env.REACT_APP_OPENAI_API_TOKEN}`,
      },
      method: 'POST',
      body: JSON.stringify({
        model: 'gpt-4o-mini',
        messages,
        temperature,
        stream: true,
      }),
    })

    if (response.status !== 200) {
      throw new Error('OpenAI API returned an error')
    }

    const stream = new ReadableStream({
      async start(controller) {
        const onParse = (event) => {
          if (event.type === 'event') {
            const data = event.data

            if (data === '[DONE]') {
              controller.close()
              return
            }

            try {
              const json = JSON.parse(data)
              const text = json.choices[0].delta.content
              const queue = encoder.encode(text)
              controller.enqueue(queue)
            } catch (e) {
              controller.error(e)
            }
          }
        }

        const parser = createParser(onParse)

        for await (const chunk of response.body) {
          parser.feed(decoder.decode(chunk))
        }
      },
    })

    return stream
  } catch (error) {
    console.error(error)
    return new Error('Error', { status: 500 })
  }
}

export { postSearch, postGPTSearch }
