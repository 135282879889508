import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import { Input, Modal, Drawer, Spin, Skeleton } from 'antd'
import { ExclamationCircleOutlined, LoadingOutlined, SettingFilled } from '@ant-design/icons'
import { Send24Filled } from '@fluentui/react-icons'
import Layout1 from '@/layouts/Layout1'
import Button from '@/components/Button'
import Answer from '@/components/Answer'
import HTMLBlock from '@/components/HTMLBlock'
import Configurations from '@/components/Configurations'
import Sparkle from '@/assets/images/icons/sparkle.svg?react'
import {
  createRagSearch,
  createGPTSearch,
  addSystemMessageToConversation,
  resetConversationsState,
} from '@/store/conversations/actions'
import { Container } from './styles'
import RelatedContentItem from './components/RelatedContentItem'

const predefinedQuestions = [
  {
    question: 'Explain me the difference between supervised and unsupervised learning',
  },
  {
    question: 'How can I group a DataFrames in Pandas?',
  },
  {
    question: 'What are the most common evaluation metrics for classification models?',
  },
  {
    question: 'What is a Pandas DataFrame and how it differs from a Serie?',
  },
]

const MainPage = () => {
  const dispatch = useDispatch()
  let [searchParams, setSearchParams] = useSearchParams()

  const token = searchParams.get('token')
  const shouldShowConfigurations = token === '1837b72d923'

  const {
    currentConversation,
    relatedContent,
    configuration,
    isConversationLoading: isLoading,
    error,
  } = useSelector((state) => state.conversations)

  const { isSystemPromptVisible } = configuration

  const [isStreamingLoading, setIsStreamingLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [answer, setAnswer] = useState('')
  const [isConfigOpen, setConfigOpen] = useState(false)

  const renderRelatedContent = () => {
    return relatedContent?.map((item) => <RelatedContentItem item={item} />)
  }

  const renderMessages = () => {
    return (
      <>
        {currentConversation
          ?.filter((m) => (isSystemPromptVisible ? true : !m?.isHidden))
          ?.map((m, i) => {
            const isUser = m?.role === 'user'
            const isHidden = m?.isHidden

            return (
              <div key={i} className="message-container">
                <div className={`message ${isUser ? 'request' : 'response'} ${isHidden ? 'hidden' : ''}`}>
                  {isUser ? <HTMLBlock content={m.content} /> : <ReactMarkdown children={m.content} />}
                </div>
              </div>
            )
          })}

        {(isLoading || isStreamingLoading) && (
          <div className="message-container">
            <div className="message response">
              {!answer?.length && <Spin indicator={<LoadingOutlined spin />} />} <Answer text={answer} />
            </div>
          </div>
        )}
      </>
    )
  }

  const sendMessageToGPT = async (customMessage, relatedContent) => {
    if (!customMessage && !message) return

    setIsStreamingLoading(true)
    setMessage('')

    let answerValue = ''

    const shouldPerformSearch = !!relatedContent?.length
    const newMessage = customMessage || message

    const stream = await dispatch(createGPTSearch(newMessage, shouldPerformSearch))

    if (stream) {
      const reader = stream.getReader()
      const decoder = new TextDecoder()
      let done = false

      while (!done) {
        const { value, done: doneReading } = await reader.read()
        done = doneReading
        const chunkValue = decoder.decode(value)
        // console.log(chunkValue)
        setAnswer((prev) => prev + chunkValue)
        answerValue += chunkValue
      }
    } else {
      answerValue = 'Sorry, I could not find any related content to help you with that question.'
      setAnswer(answerValue)
    }

    setIsStreamingLoading(false)
    dispatch(addSystemMessageToConversation(answerValue))
    setAnswer('')
  }

  const handleSendMessage = async (customMessage) => {
    if (!customMessage && !message) return

    dispatch(resetConversationsState())
    await dispatch(
      createRagSearch(customMessage || message, (relatedContent) =>
        sendMessageToGPT(customMessage || message, relatedContent),
      ),
    )

    // if (relatedContent === null) {
    //   await dispatch(createRagSearch(customMessage || message, () => sendMessageToGPT(customMessage || message)))

    //   return
    // }

    // sendMessageToGPT(customMessage || message)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage(message)
    }
  }

  const handleClearConversation = () => {
    Modal.confirm({
      title: 'Reset conversation',
      content: 'Are you sure you want to reset the current conversation?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, reset',
      cancelText: 'Keep chatting',
      onOk: () => dispatch(resetConversationsState()),
      okButtonProps: {
        danger: true,
        type: 'primary',
        disabled: isLoading || isStreamingLoading,
        loading: isLoading || isStreamingLoading,
      },
    })
  }

  useEffect(() => {
    setTimeout(() => {
      const container = document.getElementById('results')

      if (container) {
        container.scrollTop = container.scrollHeight
      }
    })
  }, [currentConversation])

  return (
    <Layout1 navbar>
      <Container className="main-page-container">
        <div className="container">
          <div className="header">
            <h4 className="title">
              <Sparkle className="chat-icon" />
              DataWars AI
            </h4>
          </div>

          {currentConversation?.length || isLoading ? (
            <div id="results" className="results">
              <div className="related-content-section">{renderRelatedContent()}</div>

              <div className="messages">
                {isLoading ? (
                  <>
                    <Skeleton className="related-content-loading" active title={false} paragraph={{ rows: 2 }} />
                    <Skeleton className="chat-loading" active title={false} paragraph={{ rows: 3 }} />
                  </>
                ) : (
                  renderMessages()
                )}

                {isLoading && (
                  <div className="message-container">
                    <div className="message response loading">
                      <Spin indicator={<LoadingOutlined spin />} />
                    </div>
                  </div>
                )}

                {error && (
                  <div className="message-container">
                    <div className="message error">{error}</div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="info-box">
              <h5 className="title">What is this?</h5>
              <p className="text">Ask me a question and I'll try to find the best content for you.</p>

              <div className="video-box">
                <iframe
                  src="https://www.loom.com/embed/585f5b4872d348d6a759bb3a59312f02?sid=8ce9b653-d93f-426f-b41e-e99211376e0e"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowfullscreen
                />
              </div>
            </div>
          )}

          <div className="actions">
            {!currentConversation?.length && (
              <div className="info-box">
                {!(isLoading || !!currentConversation?.length) && (
                  <div className="example-questions">
                    {predefinedQuestions?.map((q, i) => (
                      <div key={i} className="question-box" onClick={() => handleSendMessage(q.question)}>
                        <div className="content">
                          <p>{q.question}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}

            <div className="send-box">
              <div className="input-container">
                <Input
                  className="input"
                  rows="1"
                  placeholder={currentConversation?.length ? 'Ask another question...' : 'Ask a question...'}
                  disabled={isLoading || isStreamingLoading}
                  value={message}
                  onChange={(evt) => setMessage(evt.target.value)}
                  onKeyDown={handleKeyDown}
                />

                {isLoading || isStreamingLoading ? (
                  <Spin className="loading-btn" indicator={<LoadingOutlined spin />} />
                ) : (
                  <Send24Filled className={`send-btn ${message ? 'has-content' : ''}`} onClick={handleSendMessage} />
                )}
              </div>
            </div>
          </div>
        </div>

        {shouldShowConfigurations && (
          <Button
            className="config-btn"
            type="primary"
            size="large"
            onClick={() => setConfigOpen(true)}
            icon={<SettingFilled />}
          />
        )}
      </Container>

      {shouldShowConfigurations && (
        <Drawer open={isConfigOpen} title="Configurations" onClose={() => setConfigOpen(false)}>
          <Configurations />
        </Drawer>
      )}
    </Layout1>
  )
}

export default MainPage
