import React from 'react'
import { Spin } from 'antd'
import { Loader } from './styles'

const GlobalLoader = () => {
  return (
    <Loader>
      <Spin size="large" />
    </Loader>
  )
}

export default GlobalLoader
