import styled from 'styled-components'

export const Container = styled.div`
  .setting-box {
    width: 100%;

    .name {
      font-weight: 600;
      margin: 0px;
    }

    .value-container {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
      padding: 10px 0px 20px;
      margin-bottom: 20px;

      .ant-slider {
        width: 100%;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid ${(props) => props.theme.colors.gray4};
    padding-top: 12px;
    margin-top: 20px;
  }
`
