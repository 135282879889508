import { createSlice } from '@reduxjs/toolkit'
import { currentEnv } from '@/helpers/env'

const slice = createSlice({
  name: 'app',
  initialState: {
    environment: currentEnv,
    appError: null,
    appNotification: null,
    theme: localStorage.getItem('dw-theme') || 'light',
    isUpgradeToProModalOpen: false,
    isUpgradeToProSuccessModalOpen: false,
    pageSize: 20,
  },
  reducers: {
    APP_ERROR_UPDATED: (state, { payload }) => {
      state.appError = payload
    },
    APP_NOTIFICATION_UPDATED: (state, { payload }) => {
      state.appNotification = payload
    },
    APP_THEME_UPDATED: (state, { payload }) => {
      state.theme = payload
    },
  },
})

export const {
  APP_ERROR_UPDATED,
  APP_NOTIFICATION_UPDATED,
  APP_THEME_UPDATED,
  PPP_INFO_UPDATED,
  TOGGLE_UPGRADE_TO_PRO_MODAL,
  TOGGLE_UPGRADE_TO_PRO_SUCCESS_MODAL,
} = slice.actions
export default slice.reducer
