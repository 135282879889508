import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { useSelector } from 'react-redux'
import RouteTracking from '@/router/RouteTracking'
import MainPage from '@/pages/MainPage'

const getRoutes = () => {
  return [
    {
      element: <RouteTracking />,
      children: [
        {
          path: '/',
          element: <MainPage />,
        },
      ],
    },
  ]
}

const Router = () => {
  const { userProfile } = useSelector((state) => state.users)
  const router = createBrowserRouter(getRoutes(userProfile?.permissions))

  return <RouterProvider router={router} />
}

export default Router
