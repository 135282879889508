import axios from 'axios'
import { apiURL } from '../helpers/env'

const isServer = typeof window === 'undefined' ? true : false

const API = axios.create({
  baseURL: apiURL,
  withCredentials: true,
  headers: {
    common: {
      'Content-Type': 'application/json',
    },
  },
  emulateJSON: true,
})

API.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response) {
      if ([401, 403].includes(error.response.status) && !['users/me', 'users/login'].includes(error.config.url)) {
        if (!isServer) {
          return window.location?.replace(`/login`)
        }

        return
      }
    }

    return Promise.reject(error)
  },
)

export default API
