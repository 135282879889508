import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;
  height: 100%;

  > .container {
    height: 100%;
    background: linear-gradient(180deg, ${(props) => props.theme.colors.geekblue2} 0%, #fafafa 5%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    overflow: auto;
    padding: 12px;
    box-shadow:
      0px 3px 6px -4px rgba(0, 0, 0, 0.12),
      0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    transition: 0.3s all;

    > .header {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;

      .title {
        color: ${(props) => props.theme.colors.gray9};
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 6px;
        margin: 0px;

        .chat-icon {
          width: 24px;
          margin: 0px 2px 2px 0px;

          path {
            fill: ${(props) => props.theme.colors.geekblue5};
          }
        }
      }

      .text {
        color: ${(props) => props.theme.colors.gray8};
        margin: 0px;
      }

      .actions {
        display: flex;
        align-items: center;
        gap: 10px;

        .clear-icon {
          color: ${(props) => props.theme.colors.gray2};
          font-size: 16px;
          cursor: pointer;
          opacity: 0.8;
          transition: 0.3s all;

          &:hover {
            color: ${(props) => props.theme.colors.red6};
            opacity: 1;
          }
        }

        .expand-icon {
          color: ${(props) => props.theme.colors.gray2};
          font-size: 16px;
          cursor: pointer;
          opacity: 0.8;
          transition: 0.3s all;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .results {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      overflow-y: auto;

      .related-content-section {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;
      }

      .messages {
        max-width: 820px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .related-content-loading {
          display: block;
          overflow: auto;

          .ant-skeleton-content {
            ul {
              display: flex;
              flex-direction: column;
              gap: 12px;
              overflow: auto;
              margin-bottom: 10px;

              li {
                height: 200px;
                min-width: 820px !important;
                margin: 0px;
              }
            }
          }
        }

        .chat-loading {
          margin-top: -10px;

          .ant-skeleton-paragraph {
            margin: 0px;

            li {
              width: 100% !important;
              height: 46px;
              border-radius: 20px;
              margin-top: 10px;
            }
          }
        }

        .message-container {
          display: flex;
          word-wrap: break-word;

          .message {
            width: 100%;
            text-align: left;
            margin: 0px 0px 12px;

            &.request {
              font-size: 28px;
              font-weight: 600;
              line-height: 34px;
            }

            &.response {
              color: ${(props) => props.theme.colors.gray10};
              font-size: 16px;
              line-height: 22px;

              p,
              ul li {
                color: ${(props) => props.theme.colors.gray10};
                font-size: 16px;
                line-height: 22px;
              }

              code,
              pre code {
                font-size: 14px !important;
              }
            }

            &.error {
              background-color: ${(props) => props.theme.colors.red2};
              font-size: 18px;
              line-height: 26px;
              border-radius: 10px;
              padding: 16px 20px;
            }

            &.hidden {
              max-width: 100%;
              width: 100%;
              color: ${(props) => props.theme.colors.gray8};
              background-color: ${(props) => props.theme.colors.cyan2};
              font-size: 14px;
              line-height: 20px;
              border-radius: 10px;
              padding: 16px 20px;
            }
          }
        }
      }
    }

    .info-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-weight: 500;
        margin: 0px 0px 4px;
      }

      .text {
        margin: 0px;
      }

      .video-box {
        height: 360px;
        max-width: 564px;
        width: 100%;
        margin-top: 20px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 1px 8px 4px ${(props) => props.theme.colors.gray13}22;

        iframe {
          height: 100%;
          width: 100%;
          border: none;
        }
      }

      .example-questions {
        max-width: 980px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .question-box {
          flex: 0 0 25%;
          padding: 10px;

          > .content {
            min-height: 100px;
            height: 100%;
            border-radius: 10px;
            opacity: 0.7;
            padding: 12px 18px;
            transition: 0.3s all;
            cursor: pointer;
            user-select: none;

            > p {
              color: ${(props) => props.theme.colors.gray10};
              margin: 0px;
            }

            &:hover {
              opacity: 1;
              box-shadow: 0px 1px 6px ${(props) => props.theme.colors.gray13}26;
            }
          }

          &:nth-of-type(1) > .content {
            background-color: ${(props) => props.theme.colors.purple2};
          }

          &:nth-of-type(2) > .content {
            background-color: ${(props) => props.theme.colors.blue2};
          }

          &:nth-of-type(3) > .content {
            background-color: ${(props) => props.theme.colors.cyan2};
          }

          &:nth-of-type(4) > .content {
            background-color: ${(props) => props.theme.colors.green2};
          }
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .send-box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .input-container {
          max-width: 980px;
          width: 100%;
          position: relative;

          .input {
            width: 100%;
            color: ${(props) => props.theme.colors.gray13};
            background-color: ${(props) => props.theme.colors.gray7}22;
            font-size: 20px;
            font-family: 'Inter', sans-serif;
            border: none;
            border-radius: 64px !important;
            outline: 0;
            padding: 32px 42px;
            transition: 0.3s all;
            resize: none;

            &:hover,
            &:focus {
              border: none;
            }

            &::placeholder {
              color: ${(props) => props.theme.colors.gray7};
            }

            &:disabled {
              cursor: not-allowed;
            }
          }

          .send-btn {
            width: 28px;
            height: 28px;
            color: ${(props) => props.theme.colors.base.gray6};
            position: absolute;
            top: calc(50% - 12px);
            right: 34px;
            transition: 0.3s all;

            &.has-content {
              color: ${(props) => props.theme.colors.base.geekblue5};
              cursor: pointer;
            }
          }

          .loading-btn {
            position: absolute;
            top: calc(50% - 10px);
            right: 34px;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  .config-btn {
    position: absolute;
    top: 15%;
    right: 0px;
  }

  @media ${device.mdDown} {
    > .container {
      .info-box {
        .example-questions {
          display: block;

          .question-box {
            padding: 4px;

            > .content {
              min-height: initial;
            }
          }
        }
      }
    }
  }
`
