import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html, * {
    scroll-behavior: smooth;
    transition: 0.8s background-color;
  }

  html, body {
    font-family: 'Inter', sans-serif;
    color: ${(props) => props.theme.colors.text};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
  }

  body,
  .flat-scroll {
    overflow: auto;
    -webkit-overflow-scrolling: auto;
    scrollbar-color: rgba(127, 127, 127, 0.25) rgba(127, 127, 127, 0.05);
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 14px;
      height: 14px;
    }
    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-corner {
      background: rgba(127, 127, 127, 0.05);
    }
    ::-webkit-scrollbar-track {
      &:vertical {
        // border-left: 2px solid rgba(127, 127, 127, 0.05);
        // border-right: 1px solid rgba(127, 127, 127, 0.05);
      }
      &:horizontal {
        // border-top: 2px solid rgba(127, 127, 127, 0.05);
        // border-bottom: 1px solid rgba(127, 127, 127, 0.05);
      }
    }
    ::-webkit-scrollbar-thumb {
      background-color: rgba(127, 127, 127, 0.5);
      background-clip: content-box;
      border: 4px solid rgba(127, 127, 127, 0);
      border-radius: 12px;
      &:hover,
      &:active {
        background-color: rgba(127, 127, 127, 0.4);
      }
    }
  }

  h1 {
    font-family: 'Inter', sans-serif;
    color: ${(props) => props.theme.colors.text};
    font-size: 38px;
    font-weight: 500;
    line-height: 46px;
    margin: 0px 0px 16px;
  }

  h2 {
    font-family: 'Inter', sans-serif;
    color: ${(props) => props.theme.colors.text};
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    margin: 0px 0px 16px;
  }

  h3 {
    font-family: 'Inter', sans-serif;
    color: ${(props) => props.theme.colors.text};
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin: 0px 0px 10px;
  }

  h4 {
    font-family: 'Inter', sans-serif;
    color: ${(props) => props.theme.colors.text};
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    margin: 0px 0px 10px;
  }

  h5, h6 {
    font-family: 'Inter', sans-serif;
    color: ${(props) => props.theme.colors.text};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0px 0px 10px;
  }

  p {
    font-family: 'Inter', sans-serif;
    color: ${(props) => props.theme.colors.text};
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0px 0px 10px;
  }

  span {
    font-family: 'Inter', sans-serif;
  }

  img {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    pointer-events: none;
  }

  ul {
    li {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
    }
  }

  hr {
    border: none;
    border-top: 1px solid ${(props) => props.theme.colors.gray2};
  }

  blockquote {
    font-family: 'Inter', sans-serif;
    border-left: 5px solid ${(props) => props.theme.colors.gray6};
    padding: 0px 0px 0px 12px;
    margin: 15px;
    
    p {
      font-family: 'Inter', sans-serif;
      font-size: 13px;
      font-weight: 600;
      font-style: italic;
      padding: 5px 0px;
    }
  }

  pre {
    color: ${(props) => props.theme.colors.gray13};
    background-color: ${(props) => props.theme.colors.gray3};
    font-size: 12px !important;
    overflow: auto;
    padding: 10px;
    margin: 14px 0px;

    code {
      color: ${(props) => props.theme.colors.gray13};
      background-color: ${(props) => props.theme.colors.gray3};
      border: none;
      white-space: pre;
      margin-bottom: 0px;
    }
  }

  :not(pre) > code {
    background-color: ${(props) => props.theme.colors.gray4};
    font-size: 12px !important;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    border: 1px solid ${(props) => `${props.theme.colors.gray13}0f`};
    border-radius: 3px;
    white-space: break-spaces;
    padding: 2px 3px;
    margin: 0 1px;
  }

  .ant-modal-mask,
  .ant-modal-wrap {
    z-index: 1071 !important;

    .ant-modal {
      display: flex;
      justify-content: center;
      
      > div:nth-child(2) {
        width: 100%;
      }
      
      .ant-modal-content {
        width: 100%;
        background: ${(props) => props.theme.colors.gray1};
        box-shadow: ${(props) => `${props.theme.colors.gray13}14`} 0px 6px 16px 0px, ${(props) =>
          `${props.theme.colors.gray13}1f`} 0px 3px 6px -4px, ${(props) =>
          `${props.theme.colors.gray13}0d`} 0px 9px 28px 8px;
    
        .ant-modal-header {
          color: ${(props) => props.theme.colors.text};
          background: ${(props) => props.theme.colors.gray1};

          .ant-modal-title {
            color: ${(props) => props.theme.colors.gray13};
          }
        }

        .ant-modal-body {
          .ant-modal-confirm-title,
          .ant-modal-confirm-content {
            color: ${(props) => props.theme.colors.gray13};
          }
        }

        .ant-modal-close-x {
          svg {
            color: ${(props) => props.theme.colors.gray7};
            font-size: 18px;
          }
        }
      }
    }

    &.img-crop-modal {
      .ant-modal-content {
        width: 100%;
      }
    }
  }

  .ant-skeleton {
    .ant-skeleton-content {
      .ant-skeleton-title,
      .ant-skeleton-paragraph li {
        background: linear-gradient(90deg, ${(props) => `${props.theme.colors.gray13}0f`} 25%, ${(props) =>
          `${props.theme.colors.gray13}26`} 37%, ${(props) => `${props.theme.colors.gray13}0f`} 63%);
        background-size: 400% 100%;
      }
    }
  }

  .ant-tabs {
    .ant-tabs-tab {
      .ant-tabs-tab-btn {
        color: ${(props) => `${props.theme.colors.gray13}e0`};
      }

      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${(props) => props.theme.colors.blue6};
      }
      
      &.ant-tabs-tab-disabled .ant-tabs-tab-btn {
        color: ${(props) => props.theme.colors.disabledText};
      }
    }
  }

  .ant-table {
    .ant-table-cell {
      color: ${(props) => props.theme.colors.gray9};
      border-start-start-radius: 0px !important;
      border-start-end-radius: 0px !important;
    }
  }

  .ant-spin-lg .ant-spin-dot {
    font-size: 48px;
  }

  .ant-message {
    padding-top: 42px;

    .ant-message-notice-content {
      border-radius: 2px !important;
    }
  }

  .ant-image-preview-img {
    background-color: ${(props) => props.theme.colors.gray1};
  }

  .ant-image {
    margin: 0 auto;
  }

  .ant-layout-sider {
    .ant-menu {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }

    &.ant-layout-sider-collapsed {
    }

    .ant-layout-sider-trigger {
      color: ${(props) => props.theme.colors.gray8};
      background: ${(props) => props.theme.colors.gray1};
      box-shadow: 0px 2px 8px 0px ${(props) =>
        localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray7 : props.theme.colors.gray13}26;
    }
  }

  .ant-menu {
    .ant-menu-item {
      border-radius: 0px;
    }

    &.ant-menu-inline-collapsed {
      width: auto;
      
      .ant-menu-item {
        width: 100%;
        height: 44px;
        text-align: center;
        line-height: 48px;
        padding: 0px;
        margin: 0px;

        .ant-menu-item-icon {
          width: 18px;
          height: 18px;

          path {
            fill: ${(props) => props.theme.colors.gray8};
          }
        }

        .ant-menu-title-content {
          display: none;
        }
      }
    }

    &.ant-menu-inline {
      .ant-menu-item {
        width: 100%;
        margin: 0px;
        border-right: 3px solid transparent;

        &.ant-menu-item-selected {
          border-color: ${(props) => props.theme.colors.blue6};
        }
      }
    }
  }

  .ant-collapse {
    .ant-collapse-item {
      .ant-collapse-header {
        .ant-collapse-expand-icon {
          color: ${(props) => props.theme.colors.gray8};
        }

        .ant-collapse-header-text {
          color: ${(props) => props.theme.colors.gray10};
        }
      }

      .ant-collapse-content {
        background-color: transparent;
        color: ${(props) => props.theme.colors.gray10};
      }
    }
  }

  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }

  input, .ant-input, .ant-input-search .ant-input-affix-wrapper, .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: ${(props) => props.theme.colors.gray13};
    background-color: ${(props) => props.theme.colors.gray1};
    border-color: ${(props) => props.theme.colors.gray5} !important;
    border-radius: 2px !important;

    .ant-select-selection-placeholder,
    &::placeholder {
      color: ${(props) => props.theme.colors.disabledText};
    }
  }

  .ant-input-outlined {
    background-color: ${(props) => props.theme.colors.gray1};

    &:hover,
    &:focus {
      background-color: ${(props) => props.theme.colors.gray1};
      border-color: ${(props) => props.theme.colors.gray7} !important;
    }
  }

  .ant-input-search {
    .anticon-search {
      color: ${(props) => props.theme.colors.disabledText};
    }
  }

  .ant-radio-group {
    > label {
      color: ${(props) => props.theme.colors.gray10};
    }
  }
  
  .ant-select-dropdown {
    z-index: 1071 !important; 
  }

  .ant-input-affix-wrapper {
    color: ${(props) => props.theme.colors.gray13};
    background-color: ${(props) => props.theme.colors.gray1};
    border-color: ${(props) => props.theme.colors.gray5};
    border-radius: 2px;

    &::placeholder {
      color: ${(props) => props.theme.colors.disabledText};
    }

    &.error {
      border-color: #FF4D4F;
      animation: shake 0.3s;

      svg {
        filter: invert(40%) sepia(10%) saturate(500%) hue-rotate(133deg) brightness(100%) contrast(120%)
      }

      &:hover {
        border-color: #FF4D4F;
      }

      &:focus,
      &:active {
        box-shadow: 0 0 0 2px rgba(255, 77, 79,.2)
      }
    }

    .ant-input-prefix {
      svg {
        filter: ${localStorage.getItem('dw-theme') === 'dark' ? 'invert(1) grayscale(1) brightness(1)' : 'none'};
      }
    }
  }

  .ant-btn {
    &.ant-btn-default {
      background: ${(props) => props.theme.colors.gray1};
      color: ${(props) => props.theme.colors.gray10};
      border-color: ${(props) => props.theme.colors.gray5};
      
      &:not(:disabled):not(.ant-btn-disabled):hover {
        background: ${(props) => props.theme.colors.gray1};
      }
    }

    &.ant-btn-primary {
      color: ${(props) => props.theme.colors.base.gray1};

      &:not(:disabled):hover {
        color: ${(props) => props.theme.colors.base.gray1};
      }
    }
    
    &:disabled,
    &[disabled] {
      color: ${(props) => props.theme.colors.disabledText} !important;
      border-color: ${(props) => props.theme.colors.disabledText} !important;
    }
  }

  .ant-btn:not(.ant-btn-circle) {
    border-radius: 2px !important;
  }

  .ant-switch {
    background-color: ${(props) => props.theme.colors.gray13}40;
    
    &:hover {
      opacity: 0.95;
    }
  }

  .ant-checkbox-wrapper {
    color: ${(props) => props.theme.colors.text};
    margin-inline-start: 0px !important;
  }

  .ant-progress {
    margin: 0px;
    padding: 0px;

    .ant-progress-inner {
      margin-top: -4px;
    }
  }

  .ant-tag {
    background-color: ${(props) => props.theme.colors.gray4};
    color: ${(props) => props.theme.colors.gray8};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    border: 0px;
    border-radius: 20px;
    padding: 2px 10px;

    &.beta-tag,
    &.new-tag {
      font-size: 9px;
      font-weight: 400;
      text-transform: uppercase;
      -webkit-font-smoothing: subpixel-antialiased;
      display: flex;
      align-self: center;
      border-radius: 5px;
      padding: 0px 6px;
      }

    &.beta-tag {
      background-color: ${(props) => props.theme.colors.gray10};
      color: ${(props) => props.theme.colors.gray1};
    }

    &.new-tag {
      background-color: ${(props) => props.theme.colors.base.geekblue5};
      color: ${(props) => props.theme.colors.base.gray1};
    }
  }

.ant-steps {
  .ant-steps-item {
    .ant-steps-item-content {
      .ant-steps-item-title {
        color: ${(props) => props.theme.colors.gray10} !important;
      }
      
      .ant-steps-item-description {
        color: ${(props) => props.theme.colors.gray7} !important;
      }
    }

    &.ant-steps-item-wait {
      .ant-steps-item-content {
        .ant-steps-item-title {
          color: ${(props) => props.theme.colors.gray7} !important;
        }
      }
    }
  }
}

  .ant-rate-star {
    margin-inline-end: 2px;

    svg {
      font-size: 14px;
    }

    &:not(.ant-rate-star-full) {
      .ant-rate-star-second {
        color: ${(props) => props.theme.colors.gray5};
      }
    }
  }
  
  .ant-tooltip {
    &.on-modal {
      z-index: 1072;
    }

    .ant-tooltip-content {
      // margin-bottom: -8px;

      .ant-tooltip-inner,
      .ant-tooltip-arrow-content {
        font-size: 13px;
      }
    }

    &.large {
      max-width: 330px;
    }
  }

  .ant-dropdown {
    .ant-dropdown-menu {
      background-color: ${(props) => props.theme.colors.gray1};
      border-radius: 2px !important;

      .ant-dropdown-menu-title-content {
        > span {
          display: block;
        }
      }
    }
  }

  .ant-segmented {
    background-color: ${(props) => props.theme.colors.gray5};
    color: ${(props) => props.theme.colors.text};
      
    .ant-segmented-item {
      color: ${(props) => props.theme.colors.gray9};

      &.ant-segmented-item-selected {
        color: ${(props) => props.theme.colors.gray13};
        background-color: ${(props) => props.theme.colors.gray1};

      }
    }
  }

  .navbar-right-mobile-menu,
  .navbar-right-menu,
  .playground-menu {
    .ant-dropdown-menu {
      .ant-dropdown-menu-item {
        color: ${(props) => props.theme.colors.gray11};
        background-color: ${(props) => props.theme.colors.gray1};
        font-size: 14px;
        padding: 10px 14px;
        transition: 0.3s all;

        &:hover {
          background-color: ${(props) => props.theme.colors.gray3};
        }

        .ant-dropdown-menu-title-content {
          .menu-item,
          .mobile-menu-item {
            display: flex; 
            align-items: center;
            gap: 12px;

            svg {
              width: 14px;
              height: 14px;
              color: ${(props) => props.theme.colors.gray6};
            }

            .tag-item {
              display: flex;
              gap: 6px;
            }
          }
        }

        &.ant-dropdown-menu-item-danger {
          .ant-dropdown-menu-title-content {
            .menu-item,
            .mobile-menu-item {
              svg {
                color: ${(props) => props.theme.colors.red6};
              }
            }
          }

          &:hover {
            .ant-dropdown-menu-title-content {
              .menu-item,
              .mobile-menu-item {
                svg {
                  color: ${(props) => props.theme.colors.gray1};
                }
              }
            }
          }
        }
      }
    }
  }

  .playground-menu {
    .ant-dropdown-menu {
      .ant-dropdown-menu-item {
        font-size: 12px;
        padding: 8px 14px;

        .ant-dropdown-menu-title-content {
          .menu-item {
            gap: 10px;

            svg {
              color: ${(props) => props.theme.colors.gray11};
            }
          }
        }
      }
    }
  }

  .navbar-right-mobile-menu {
    max-width: 100%;
    width: 100% !important;
    top: 0px !important;
    margin-top: 64px !important;
    overflow: auto;
    inset: 0px 0px auto auto !important;

    .ant-dropdown-menu {
      height: calc(100vh - 64px);
      background-color: ${(props) => props.theme.colors.gray1};
      border-top: 2px solid ${(props) => props.theme.colors.gray5};
      box-shadow: none;

      .ant-dropdown-menu-item {
        font-size: 16px;
        padding: 20px 30px;
      }
    }
  }

  .introjs-tooltip {
    &.single-step {
      .introjs-bullets {
        display: none;
      }
      
      .introjs-tooltipbuttons {
        border: none;
      }
    }

    &.hide-tooltip {
      display: none !important;
    }
  }

  .auth-modal {
    min-height: calc(100vh - 12px);
    height: calc(100% - 12px);
    width: 100% !important;
    top: 12px;

    > div:nth-child(2) {
      width: 100%;
    }
  }

  .email-verification-modal {
    &.done .ant-modal-body {
      background-color: ${(props) => props.theme.colors.blue3};
    }
  }

  .upgrade-to-pro-modal {
    max-width: 1024px;
    width: 100% !important;
  }

  .public-profile-modal {
    max-width: 1024px;
    width: 100% !important;
  }

  .playground-wizard-modal {
    width: 100% !important;
    height: 100%;
    top: 0;
  }

  .playground-publish-modal {
    display: flex;
    align-self: center;
  }
`

export default GlobalStyle
