import styled from 'styled-components'

export const Container = styled.div`
  padding: 6px;

  .related-content {
    max-width: 820px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    color: ${(props) => props.theme.colors.gray13};
    border: 1px solid ${(props) => props.theme.colors.gray8}22;
    border-radius: 8px;
    padding: 12px 14px;
    transition: 0.3s all;

    .info {
      display: flex;
      gap: 20px;
      margin-bottom: 6px;

      .header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;

        .pre-title {
          margin: 0px;
        }

        .title {
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin: 0px;
        }
      }

      iframe {
        background-color: ${(props) => props.theme.colors.gray3};
        border: none;
      }
    }

    .passages-container {
      .ant-collapse {
        background-color: ${(props) => props.theme.colors.gray1};
        border: 1px solid ${(props) => props.theme.colors.gray4};
        border-radius: 4px;

        .ant-collapse-item {
          border-color: ${(props) => props.theme.colors.gray4};

          .ant-collapse-content {
            border-color: ${(props) => props.theme.colors.gray4};

            .ant-collapse-content-box {
              padding: 0px;
            }
          }
        }
      }

      .passage-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;

        .time,
        .similarity {
          margin: 0px;
        }
      }

      .passage {
        display: flex;
        flex-direction: column;
        gap: 12px;
        border: none !important;

        .text {
          font-size: 12px;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          margin: 0px;

          &.bold {
            font-weight: 500;
          }
        }

        .passage-actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 12px;

          .link {
            margin: 0px;
          }
        }
      }
    }
  }
`
