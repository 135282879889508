import { configureStore } from '@reduxjs/toolkit'
import appReducer from './app'
import conversationsReducer from './conversations'
import usersReducer from './users'

const reducer = {
  app: appReducer,
  conversations: conversationsReducer,
  users: usersReducer,
}

const preloadedState = {}

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    return [...getDefaultMiddleware()]
  },
  devTools: import.meta.env.REACT_APP_ENVIRONMENT !== 'production',
  preloadedState,
  enhancers: (getDefaultEnhancers) => {
    return [...getDefaultEnhancers()]
  },
})

export default store
